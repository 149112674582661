import { template as template_be13fe5f1f6c4e739a25f9e21d01a406 } from "@ember/template-compiler";
const WelcomeHeader = template_be13fe5f1f6c4e739a25f9e21d01a406(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
