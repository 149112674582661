import { template as template_8d6328b5f5e0499892357bf91e7c1e71 } from "@ember/template-compiler";
import { concat, hash } from "@ember/helper";
import { LinkTo } from "@ember/routing";
import i18n from "discourse-common/helpers/i18n";
const GroupActivityFilter = template_8d6328b5f5e0499892357bf91e7c1e71(`
  <li>
    <LinkTo
      @route={{concat "group.activity." @filter}}
      @query={{hash category_id=@categoryId}}
    >
      {{i18n (concat "groups." @filter)}}
    </LinkTo>
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default GroupActivityFilter;
