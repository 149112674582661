import { template as template_d1339e02f9914c819e3e6724b626c064 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d1339e02f9914c819e3e6724b626c064(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
