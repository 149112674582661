import { template as template_38f85dc9b8684a1ca4dfcae05e3c93de } from "@ember/template-compiler";
const FKLabel = template_38f85dc9b8684a1ca4dfcae05e3c93de(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
